<template>
  <router-link :to="{ name: 'AdminProvider' }" class="navbar-item">
    Atur Jenis Pasaran
  </router-link>

  <router-link :to="{ name: 'AdminGame' }" class="navbar-item">
    Atur Pasaran
  </router-link>

  <router-link :to="{ name: 'AdminAdmin' }" class="navbar-item">
    Atur Admin
  </router-link>
</template>

<script>
export default {};
</script>

<style></style>
